import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { RootState } from '../../../app/store';
import { axiosInstance } from '../../../helpers/api_helper';
import moment from 'moment';
import {
  resendVerificationMail,
  reset2Fa,
  sendPasswordOrId,
  setUserActivationStatus
} from './actions/actions';
import {
  BootcampEvent,
  Bring3Activations,
  Bring3Details,
  Bring3Info,
  Conversion,
  CurrentVirtualOfficeLicence,
  Event,
  FinUpInfo,
  HubsBigWhaleInfo,
  HubsBigWhaleSummary,
  HubsGamingInfo,
  HubsPaymentInfo,
  HubsStakingInfo,
  HubsTradingInfo,
  Invoice,
  KycStatusType,
  Transaction,
  UnilevelType,
  UpdateUserAddressType,
  UpdateUserDetailsType,
  UserComment,
  UserDetailsType,
  UserHubsInfo,
  UserHubsSummary,
  WalletSummary,
  WithdrawAvailability,
  Withdrawal
} from './types';

export const cleatUserDetailsState = createAsyncThunk(
  'userDetails/clear',
  () => {
    return true;
  }
);

export const getUserDetails = createAsyncThunk(
  'userDetails/fetch',
  async (userId: number) => {
    const response = await axiosInstance.get(`/user/mlm/${userId}`);
    return response.data.data;
  }
);

export const getUserHubsInfo = createAsyncThunk(
  'userDetails/getUserHubsInfo',
  async (userId: number) => {
    const response = await axiosInstance.get(`/user/mlm/portfolio/${userId}`);
    return response.data.data as UserHubsInfo;
  }
);

export const updateUserEmail = createAsyncThunk(
  'userDetails/updateEmail',
  async (detailsToCompare: UserDetailsType, { getState, rejectWithValue }) => {
    const { userDetails } = getState() as RootState;

    if (
      detailsToCompare === undefined &&
      userDetails.data === detailsToCompare
    ) {
      return;
    }

    try {
      const response = await axiosInstance.put(`/user/mlm/email/change`, {
        userId: detailsToCompare.id,
        email: detailsToCompare.email
      });

      toast.success(response.data.message);

      return detailsToCompare as UserDetailsType;
    } catch (error: any) {
      toast.error(error.response.data.error);
      return rejectWithValue(error.response.data.error);
    }
  }
);

export const updateUserPersonalDetails = createAsyncThunk(
  'userDetails/updateUserDetails',
  async (detailsToCompare: UserDetailsType, { getState, rejectWithValue }) => {
    const { userDetails } = getState() as RootState;

    if (
      detailsToCompare === undefined &&
      userDetails.data === detailsToCompare
    ) {
      return;
    }

    const object: UpdateUserDetailsType = { userId: detailsToCompare.id };

    if (detailsToCompare.name !== userDetails.data.name)
      object['name'] = detailsToCompare.name;
    if (detailsToCompare.surname !== userDetails.data.surname)
      object['surname'] = detailsToCompare.surname;

    try {
      const response = await axiosInstance.put(
        `/user/mlm/personal-details/change`,
        object
      );

      toast.success(response.data.message);

      return detailsToCompare as UserDetailsType;
    } catch (error: any) {
      toast.error(error.response.data.error);
      return rejectWithValue(error.response.data.error);
    }
  }
);

export const updateUserAddress = createAsyncThunk(
  'userDetails/updateAddress',
  async (detailsToCompare: UserDetailsType, { getState, rejectWithValue }) => {
    const { userDetails } = getState() as RootState;

    if (
      detailsToCompare === undefined &&
      userDetails.data === detailsToCompare
    ) {
      return;
    }

    const object: UpdateUserAddressType = {
      addressId: detailsToCompare.address.id
    };

    if (
      detailsToCompare.address.addressLine1 !==
      userDetails.data.address.addressLine1
    ) {
      object['addressLine1'] = detailsToCompare.address.addressLine1;
    }

    if (detailsToCompare.address.zipCode !== userDetails.data.address.zipCode) {
      object['zipCode'] = detailsToCompare.address.zipCode;
    }

    if (detailsToCompare.address.country !== userDetails.data.address.country) {
      object['country'] = detailsToCompare.address.country;
    }

    if (detailsToCompare.address.city !== userDetails.data.address.city) {
      object['city'] = detailsToCompare.address.city;
    }

    try {
      const response = await axiosInstance.put(
        `/user/mlm/address/change`,
        object
      );

      toast.success(response.data.message);

      return detailsToCompare as UserDetailsType;
    } catch (error: any) {
      toast.error(error.response.data.error);
      return rejectWithValue(error.response.data.error);
    }
  }
);

export const verifyKyc = createAsyncThunk(
  'userDetails/verifyKyc',
  async (
    { userId, isValid }: { userId: number; isValid: boolean },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.put(`/user/mlm/kyc/verify`, {
        userId,
        isValid
      });

      toast.success(response.data.message);

      return response.data.data.kycStatus as KycStatusType;
    } catch (error: any) {
      toast.error(error.response.data.error);
      return rejectWithValue(error.response.data.error);
    }
  }
);

export const addComment = createAsyncThunk(
  'userDetails/addComment',
  async (
    {
      userId,
      title,
      description
    }: { userId: number; title: string; description: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post(`/user/mlm/comment`, {
        userId,
        title,
        description
      });

      toast.success(response.data.message);

      return response.data.data as UserComment;
    } catch (error: any) {
      toast.error(error.response.data.error);
      return rejectWithValue(error.response.data.error);
    }
  }
);

export const getBootcampEvent = createAsyncThunk(
  'userDetails/getBootcampEvent',
  async (userId: number) => {
    const response = await axiosInstance.get(
      `/ranking-event/cabo-san-lucas/${userId}`
    );
    return response.data.data as BootcampEvent;
  }
);

export const getInvoices = createAsyncThunk(
  'userDetails/invoices',
  async (userId: number) => {
    const response = await axiosInstance.get(`/mlm/user/accounting/invoices`, {
      params: { userId, count: 50 }
    });
    return response.data.data.invoices as Invoice[];
  }
);

export const getTransactions = createAsyncThunk(
  'userDetails/transactions',
  async (userId: number) => {
    const response = await axiosInstance.get(
      `/mlm/user/accounting/transactions`,
      {
        params: { userId, countPerPage: 50, numberOfPage: 1 }
      }
    );
    return response.data.data.transactions as Transaction[];
  }
);

export const getWithdrawals = createAsyncThunk(
  'userDetails/withdrawals',
  async (userId: number) => {
    const response = await axiosInstance.get(
      `/mlm/user/accounting/withdrawals`,
      {
        params: { userId, countPerPage: 50, numberOfPage: 1 }
      }
    );
    return response.data.data.withdrawals as Withdrawal[];
  }
);

export const getConversions = createAsyncThunk(
  'userDetails/conversions',
  async (userId: number) => {
    const response = await axiosInstance.get(
      `/mlm/user/accounting/conversions`,
      {
        params: { userId, countPerPage: 50, numberOfPage: 1 }
      }
    );
    return response.data.data.conversions as Conversion[];
  }
);

export const getUserHubsSummary = createAsyncThunk(
  'userDetails/getUserHubsSummary',
  async (userId: number) => {
    const { data } = await axiosInstance.get(`/my-hubs/summary/${userId}`);
    return data.data as UserHubsSummary;
  }
);

export const getUserHubsTradingInfo = createAsyncThunk(
  'userDetails/getUserHubsTradingInfo',
  async (userId: number) => {
    const { data } = await axiosInstance.get(`/my-hubs/trading/${userId}`);
    return data.data as HubsTradingInfo;
  }
);

export const getUserHubsStakingInfo = createAsyncThunk(
  'userDetails/getUserHubsStakingInfo',
  async (userId: number) => {
    const { data } = await axiosInstance.get(`/my-hubs/staking/${userId}`);
    return data.data as HubsStakingInfo;
  }
);

export const getUserHubsGamingInfo = createAsyncThunk(
  'userDetails/getUserHubsGamingInfo',
  async (userId: number) => {
    const { data } = await axiosInstance.get(`/my-hubs/gaming/${userId}`);
    return data.data as HubsGamingInfo;
  }
);

export const getUserHubsPaymentInfo = createAsyncThunk(
  'userDetails/getUserHubsPaymentInfo',
  async (userId: number) => {
    const { data } = await axiosInstance.get(`/my-hubs/payment/${userId}`);
    return data.data as HubsPaymentInfo;
  }
);

export const getUserHubsBigWhaleInfo = createAsyncThunk(
  'userDetails/getUserHubsBigWhaleInfo',
  async (userId: number) => {
    const { data } = await axiosInstance.get(`/my-hubs/big-whale/${userId}`);

    const { data: summary } = await axiosInstance.get(
      `/meme-coin/${userId}/summary`
    );

    const bigWhaleSummary = {
      ...data.data,
      summary: {
        ...data.data.summary,
        ...summary.data.summary
      }
    };

    return bigWhaleSummary as HubsBigWhaleInfo;
  }
);

export const getTravelInfo = createAsyncThunk(
  'userDetails/getTravelInfo',
  async (userId: number) => {
    const { data } = await axiosInstance.get(`/my-hubs/revolution/${userId}`);

    return data.data;
  }
);

export const updateWillyCoinsSummary = createAsyncThunk(
  'updateWillyCoinsSummary',
  ({
    totalMemeCoins,
    totalCoinsPerChange,
    memeCoinsFromBigWhale,
    finupCoinsPerChange,
    memeCoinsFromBring3,
    fastStartCoinsPerChange
  }: HubsBigWhaleSummary) => {
    let totalWillyMemeCoins = {
      totalMemeCoins: (totalMemeCoins += totalCoinsPerChange)
    };

    let totalFineUpMemeCoins = {
      memeCoinsFromBigWhale: (memeCoinsFromBigWhale += finupCoinsPerChange)
    };

    let totalFastStartUpMemeCoins = {
      memeCoinsFromBring3: (memeCoinsFromBring3 += fastStartCoinsPerChange)
    };

    return {
      totalWillyMemeCoins,
      totalFineUpMemeCoins,
      totalFastStartUpMemeCoins
    };
  }
);

export const getFinUpInfo = createAsyncThunk(
  'userDetails/getFinUpInfo',
  async (userId: number) => {
    const { data } = await axiosInstance.get(`/meme-coin/${userId}/finup`);

    return data.data as FinUpInfo;
  }
);

export const getBring3Info = createAsyncThunk(
  'userDetails/getBring3Info',
  async (userId: number) => {
    const { data } = await axiosInstance.get(`/meme-coin/${userId}/bring-3`);

    return data.data as Bring3Info;
  }
);

export const getBring3Details = createAsyncThunk(
  'userDetails/getBring3Details',
  async (userId: number) => {
    const { data } = await axiosInstance.get(`/meme-coin/${userId}/details`);

    return data.data as Bring3Details;
  }
);

export const getBring3Activations = createAsyncThunk(
  'userDetails/getBring3Activations',
  async (userId: number) => {
    const { data } = await axiosInstance.get(
      `/meme-coin/${userId}/bring-3-activations`
    );

    return data.data as Bring3Activations;
  }
);

export interface UserDetailsState {
  data: UserDetailsType;
  unilevel: UnilevelType;
  ranking: any;
  invoices: Invoice[];
  transactions: Transaction[];
  withdrawals: Withdrawal[];
  conversions: Conversion[];
  hubsInfo: UserHubsInfo;
  bangkokPromotion: Event;
  mexicoPromotion: Event;
  cruisesPromotion: Event;
  loading: boolean;
  editState: boolean;
  bootcampEvent: BootcampEvent | null;

  payment: HubsPaymentInfo | null;
  gaming: HubsGamingInfo | null;
  staking: HubsStakingInfo | null;
  trading: HubsTradingInfo | null;
  bigWhale: HubsBigWhaleInfo | null;
  travel: HubsGamingInfo | null;
  walletSummary: WalletSummary | null;
  loadingMemeCoins: boolean;
  finUp: FinUpInfo | null;
  bring3: Bring3Info | null;
  bring3Details: Bring3Details | null;
  bring3Activations: Bring3Activations | null;
  withdrawAvailability: WithdrawAvailability | null;
  currentVirtualOfficeLicence: CurrentVirtualOfficeLicence | null;
  lifetimeRank: string;
  qualifiedRank: string;
}

const initialState = {
  data: {},
  bootcampEvent: null,
  unilevel: {},
  ranking: [],
  loading: false,
  editState: false,

  payment: null,
  gaming: null,
  staking: null,
  trading: null,
  bigWhale: null,
  travel: null,
  walletSummary: null,
  loadingMemeCoins: false,
  finUp: null,
  bring3: null,
  bring3Details: null,
  bring3Activations: null,
  withdrawAvailability: null,
  currentVirtualOfficeLicence: null,
  lifetimeRank: '',
  qualifiedRank: ''
} as UserDetailsState;

const userDetailsSlice = createSlice({
  name: 'userDetails',
  initialState,
  reducers: {
    removeUserFromState(state) {
      state = initialState;
    },
    toggleEditState(state) {
      state.editState = !state.editState;
    }
  },
  extraReducers: (builder) => {
    //CLEAR STATE
    builder.addCase(cleatUserDetailsState.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.bring3 = null;
      state.finUp = null;
      state.bring3Details = null;
    });

    builder.addCase(getUserHubsSummary.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserHubsSummary.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.withdrawAvailability = payload?.withdrawAvailability || null;
      state.currentVirtualOfficeLicence =
        payload?.currentVirtualOfficeLicence || null;
      state.lifetimeRank = payload?.lifetimeRank || '';
      state.qualifiedRank = payload?.qualifiedRank || '';
      state.walletSummary = payload?.walletSummary || null;
    });
    builder.addCase(getUserHubsSummary.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getUserHubsTradingInfo.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserHubsTradingInfo.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.trading = payload;
    });
    builder.addCase(getUserHubsTradingInfo.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getUserHubsStakingInfo.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserHubsStakingInfo.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.staking = payload;
    });
    builder.addCase(getUserHubsStakingInfo.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getUserHubsGamingInfo.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserHubsGamingInfo.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.gaming = payload;
    });
    builder.addCase(getUserHubsGamingInfo.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getUserHubsPaymentInfo.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserHubsPaymentInfo.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.payment = payload;
    });
    builder.addCase(getUserHubsPaymentInfo.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getUserHubsBigWhaleInfo.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserHubsBigWhaleInfo.fulfilled, (state, { payload }) => {
      const now = moment();
      const midnight = moment.utc().startOf('day');
      const secondsSinceMidnight = now.diff(midnight, 'seconds');

      state.loading = false;

      state.bigWhale = {
        ...payload,

        summary: {
          ...payload.summary,
          memeCoinsFromBigWhale:
            payload.summary.memeCoinsFromBigWhale +
            (secondsSinceMidnight / payload.summary.changePeriodInSeconds) *
              payload.summary.finupCoinsPerChange,

          totalMemeCoins:
            payload.summary.totalMemeCoins +
            (secondsSinceMidnight / payload.summary.changePeriodInSeconds) *
              payload.summary.totalCoinsPerChange,

          memeCoinsFromBring3:
            payload.summary.memeCoinsFromBring3 +
            (secondsSinceMidnight / payload.summary.changePeriodInSeconds) *
              payload.summary.fastStartCoinsPerChange,

          changePeriodInSeconds: payload.summary.changePeriodInSeconds,
          finupCoinsPerChange: payload.summary.finupCoinsPerChange,
          fastStartCoinsPerChange: payload.summary.fastStartCoinsPerChange,
          totalCoinsPerChange: payload.summary.totalCoinsPerChange
        }
      };
    });
    builder.addCase(getUserHubsBigWhaleInfo.rejected, (state) => {
      state.loading = false;
    });

    //Travel

    builder.addCase(getTravelInfo.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTravelInfo.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.travel = payload;
    });
    builder.addCase(getTravelInfo.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(updateWillyCoinsSummary.fulfilled, (state, { payload }) => {
      state.bigWhale!.summary.totalMemeCoins =
        payload.totalWillyMemeCoins.totalMemeCoins;
      state.bigWhale!.summary.memeCoinsFromBigWhale =
        payload.totalFineUpMemeCoins.memeCoinsFromBigWhale;
      state.bigWhale!.summary.memeCoinsFromBring3 =
        payload.totalFastStartUpMemeCoins.memeCoinsFromBring3;
    });

    builder.addCase(getUserDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserDetails.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload.user as UserDetailsType;
      state.qualifiedRank = payload.info.qualifiedRank;
      state.unilevel = payload.unilevel;
      state.ranking = payload.ranking?.packageRanks.filter(
        (rank: any) => rank.packageRankId !== 16 && rank.packageRankId !== 17
      );
      // state.invoices = payload.invoices;
      // state.transactions = payload.transactions;
      // state.withdrawals = payload.withdrawals;
      state.bangkokPromotion = payload.bangkokPromotion;
      state.mexicoPromotion = payload.mexicoPromotion;
      state.cruisesPromotion = payload.cruisesPromotion;
    });
    builder.addCase(getUserDetails.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getUserHubsInfo.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserHubsInfo.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.hubsInfo = payload;
    });
    builder.addCase(getUserHubsInfo.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(updateUserEmail.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateUserEmail.fulfilled, (state, { payload }) => {
      state.loading = false;
      if (payload) state.data = payload;
    });
    builder.addCase(updateUserEmail.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(updateUserPersonalDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      updateUserPersonalDetails.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        if (payload) state.data = payload;
      }
    );
    builder.addCase(updateUserPersonalDetails.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(updateUserAddress.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateUserAddress.fulfilled, (state, { payload }) => {
      state.loading = false;
      if (payload) state.data = payload;
    });
    builder.addCase(updateUserAddress.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(verifyKyc.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(verifyKyc.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data.kycStatus = payload;
    });
    builder.addCase(verifyKyc.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(addComment.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addComment.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data.adminComments.push(payload);
    });
    builder.addCase(addComment.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(sendPasswordOrId.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(sendPasswordOrId.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(sendPasswordOrId.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(resendVerificationMail.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(resendVerificationMail.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(resendVerificationMail.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(reset2Fa.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(reset2Fa.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(reset2Fa.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(setUserActivationStatus.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(setUserActivationStatus.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data.activationStatus = payload;
    });
    builder.addCase(setUserActivationStatus.rejected, (state) => {
      state.loading = false;
    });

    //Bootcamp

    builder.addCase(getBootcampEvent.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getBootcampEvent.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.bootcampEvent = payload;
    });
    builder.addCase(getBootcampEvent.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getInvoices.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getInvoices.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.invoices = payload;
    });
    builder.addCase(getInvoices.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getTransactions.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTransactions.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.transactions = payload;
    });
    builder.addCase(getTransactions.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getWithdrawals.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getWithdrawals.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.withdrawals = payload;
    });
    builder.addCase(getWithdrawals.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getConversions.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getConversions.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.conversions = payload;
    });
    builder.addCase(getConversions.rejected, (state) => {
      state.loading = false;
    });

    //FinUp
    builder.addCase(getFinUpInfo.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getFinUpInfo.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.finUp = payload;
    });
    builder.addCase(getFinUpInfo.rejected, (state) => {
      state.loading = false;
    });

    //Bring3
    builder.addCase(getBring3Info.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getBring3Info.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.bring3 = payload;
    });
    builder.addCase(getBring3Info.rejected, (state) => {
      state.loading = false;
    });

    //Bring3 Details
    builder.addCase(getBring3Details.pending, (state) => {
      state.loadingMemeCoins = true;
    });
    builder.addCase(getBring3Details.fulfilled, (state, { payload }) => {
      state.loadingMemeCoins = false;
      state.bring3Details = payload;
    });
    builder.addCase(getBring3Details.rejected, (state) => {
      state.loadingMemeCoins = false;
    });

    //Bring3 Activations
    builder.addCase(getBring3Activations.pending, (state) => {
      state.loadingMemeCoins = true;
    });
    builder.addCase(getBring3Activations.fulfilled, (state, { payload }) => {
      state.loadingMemeCoins = false;
      state.bring3Activations = payload;
    });
    builder.addCase(getBring3Activations.rejected, (state) => {
      state.loadingMemeCoins = false;
    });
  }
});

export const { removeUserFromState, toggleEditState } =
  userDetailsSlice.actions;
export default userDetailsSlice.reducer;
